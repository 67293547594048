//Migrated
<template>
  <div class="layout-wrapper">
    <slot />
    <slot name="header" />
    <div
      v-if="shouldHaveSolresor"
      class="w-full px-4 top-section layout-container mx-auto"
      :class="containerClass"
    >
      <slot name="solresorContent" />
    </div>
    <div
      class="relative"
      :class="containerClass"
    >
      <div
        v-if="shouldNotBeEmpty"
        class="container-content w-full px-4 banner-layout static mx-auto"
        :class="`banner-layout-${getBannerType}`"
      >
        <slot name="banner" />
        <div
          v-if="shouldHaveContainer"
          class="layout-container mx-auto h-full"
        >
          <slot name="contentNoWrapper" />
          <slot
            v-if="!$isSol"
            name="sidebar"
          />
          <div
            v-if="shouldHaveWrapper"
            class="w-12/12 px-0 static float-left float-print-none"
            :class="{ 'lg:w-9/12': !$isSol }"
          >
            <slot name="hero" />
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    containerClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState(useRootStore, {
      bannerType: 'pageBannerType',
    }),

    getBannerType () {
      return this.bannerType ?? 'default'
    },

    shouldHaveWrapper () {
      return !!this.$slots?.hero || !!this.$slots?.content
    },

    shouldHaveContainer () {
      return !!this.$slots?.contentNoWrapper || !!this.$slots?.sidebar || this.shouldHaveWrapper
    },

    shouldNotBeEmpty () {
      return !!this.$slots?.banner || this.shouldHaveContainer
    },

    shouldHaveSolresor () {
      return !!this.$slots?.solresorContent
    },
  },
})
</script>

<style lang="scss">
.layout-head {
  @media (max-width: theme('screens.lg')) {
    height: 550px;
  }

  @media (max-width: theme('screens.md')) {
    min-height: 470px;
    max-height: 85vh;
  }

  @media print {
    height: 450px;
  }
}

.layout-container {
  @media (min-width: theme('screens.xl')) {
    width: 85% !important;
  }
}

/*! purgecss start ignore */
.identity--rolfs {
  .banner-layout {
    &.container-content {
      max-width: $container-width;
    }
  }
}

.identity--solresor {
  .banner-layout-large {
    .layout-container {
      max-width: 1325px;
    }
  }
}

.banner-layout {
  &:not(.banner-layout-map) {
    #side-menu {
      @media (min-width: theme('screens.lg')) {
        margin-top: $top-section-margin-top;
      }
    }
  }

  &.banner-layout-small {
    .layout-head {
      height: 420px;
    }
  }

  &.banner-layout-default {
    .layout-head {
      @media (min-width: theme('screens.md')) {
        height: 630px;
      }
    }
  }

  &.banner-layout-large {
    .layout-head {
      @media (min-width: theme('screens.md')) {
        height: 700px;
      }
    }
  }
}

.banner-text-container {
  padding: 0 120px 0 120px;

  .text-7xl {
    letter-spacing: 1px;
  }

  .banner-text-title {
    line-height: 1.1em;
  }
  .banner-text-subtitle {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  @media (min-width: theme('screens.sm')) {
    max-height: 300px;
    padding: 0;
  }

  @media (max-width: theme('screens.md')) {
    max-height: 300px;

    .banner-text-title {
      font-size: 3.4rem;
    }
    .banner-text-subtitle {
      font-size: 1.7rem;
    }
  }

  @media (max-width: theme('screens.3xl')) {
    padding: 0 .625em 0 .625em;
  }
}
/*! purgecss end ignore */
</style>
